<!--
 * 作者：zhang
 * 时间：2024/8/13 下午4:17
 * 功能：
 *
-->

<template>
  <div>
    <!-- <van-nav-bar title="我的观影日历" /> -->
    <tabbar></tabbar>
    <div class="calendar">
      <div class="calendar-year">
        <van-icon
          name="arrow-left"
          color="#1492FF"
          @click="currentWeekIndex--"
        />
        <span>
          {{ weekList | forYD }}
          {{
            nowWeekIndex === currentWeekIndex
              ? '本周'
              : `第${currentWeekIndex}周`
          }}</span
        >
        <van-icon name="arrow" color="#1492FF" @click="currentWeekIndex++" />
      </div>
      <div class="calendar-week">
        <div
          class="calendar-week-item"
          v-for="item in weekList"
          :key="item.weekName"
        >
          <div class="calendar-week-item-weekName">
            {{ item.weekName }}
          </div>
          <div
            class="calendar-week-item-date"
            :class="{ active: selectDate === item.date }"
            @click="selectDate = item.date"
          >
            {{ getDays(item.date) }}
          </div>
          <!--          {{selectDate}}/ {{item.date}}-->
        </div>
      </div>
      <!--    点播记录-->
      <div class="record">
        <div
          class="record-item"
          v-for="(record, index) in recordList"
          :key="index"
        >
          <div class="record-item-title">
            {{ formatDateTime(record.watchTime) }}
          </div>
          <div class="record-item-address">
            {{ record.showPoint.address }}
          </div>
          <div class="record-item-film">
            <div>
              <film-card
                :movie-id="record.film.id"
                :movie-img="record.film.filmPicture"
                :movie-title="record.film.name"
                :movie-score="record.film.score"
                :movie-length="record.film.filmLength"
                :movie-description="record.film.synopsis"
                :movie-actor="record.film.actor"
                :movie-type="record.film.type"
                :movie-play-num="record.film.clickCount"
              ></film-card>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!recordList.length">
        <van-empty description="暂无观影记录" />
      </div>
    </div>
  </div>
</template>
<script>
import Tabbar from '@comp/tabbar/index.vue'
import filmCard from '@comp/movieCard/index'
import moment from 'moment'
import { api } from '@api/index'

export default {
  name: 'index',
  components: { Tabbar, filmCard },
  data() {
    return {
      // 当前年月
      currentDate: new Date(),
      // 选中的周数
      currentWeekIndex: 0,
      // 现在时间周数
      nowWeekIndex: moment().week(),
      // 选中日期
      selectDate: null,
      weekList: [],
      recordList: [],
    }
  },
  mounted() {
    this.selectDate = moment().format('YYYY-MM-DD')
    this.currentWeekIndex = this.nowWeekIndex
    // 根据当天日期填充这周的日期数据
    this.updateCalendarData()
    this.getWeekList()
  },
  methods: {
    // 获取某一日的观影日历
    updateCalendarData() {
      api.personal
        .getPersonalCalendar({
          startDate: `${this.selectDate}`,
          endDate: `${this.selectDate}`,
        })
        .then((res) => {
          this.recordList = res
        })
    },
    getWeekList() {
      const firstDay = this.moment().week(this.currentWeekIndex).weekday(0)
      // console.log({firstDay: firstDay.format('YYYY-MM-DD')});
      let weekList = []
      for (let i = 0; i < 7; i++) {
        const day = firstDay.clone().add(i, 'days')
        // console.log({day: day.format('YYYY-MM-DD'), firstDay: firstDay.format('YYYY-MM-DD'), week: day.weekday()})
        weekList.push({
          weekName: this.getWeekName(day.weekday()),
          // date: day.year() + '-' + (day.month() + 1) + '-' + day.date(),
          date: day.format('YYYY-MM-DD'),
          isToday: day.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
        })
      }
      console.log({ weekList: weekList })
      this.weekList = weekList
    },
    // 格式化星期
    getWeekName(day) {
      switch (day) {
        case 0:
          return '周一'
        case 1:
          return '周二'
        case 2:
          return '周三'
        case 3:
          return '周四'
        case 4:
          return '周五'
        case 5:
          return '周六'
        case 6:
          return '周日'
      }
    },
    getDays(date) {
      const days = moment(new Date()).format('D')
      return moment(date + ' 00:00:00').format('D') === days
        ? '今'
        : moment(date + ' 00:00:00').format('D')
    },
    // 2020-1-1 10:00:00 转 2020年1月1日 | 星期一 | 上午 10:01
    formatDateTime(dateTime) {
      const date = moment(dateTime)
      const weekName = this.getWeekName(date.weekday())
      let hour = date.hour()
      const minute = date.minute()
      let amOrPm = '上午'
      if (hour >= 12) {
        amOrPm = '下午'
        hour = hour - 12
      }
      if (hour >= 18) {
        amOrPm = '晚上'
        hour = hour - 12
      }
      return `${date.format(
        'YYYY年M月D日',
      )} | ${weekName} | ${amOrPm} ${hour}:${
        minute < 10 ? '0' + minute : minute
      }`
    },
  },
  filters: {
    forYD(list) {
      let starDate = {year: 0, month: 0, day: 0}
      let endDate = {year: 0, month: 0, day: 0}
      list.map((item) => {
        const date = moment(item.date)
        if(starDate.year === 0){
          starDate = {year: date.year(), month: date.month() + 1, day: date.date()}
          endDate = {year: date.year(), month: date.month() + 1, day: date.date()}
        }
        else if(date.year() !== starDate.year || date.month() + 1 !== starDate.month){
          endDate = {year: date.year(), month: date.month() + 1, day: date.date()}
        }
      })
      return `${starDate.year===endDate.year?starDate.year:starDate.year+'-'+endDate.year}年${starDate.month===endDate.month?starDate.month:starDate.month+'-'+endDate.month}月`
      
    },
  },
  watch: {
    selectDate: {
      handler(newVal, oldVal) {
        this.updateCalendarData()
      },
    },
    currentWeekIndex: {
      handler(newVal, oldVal) {
        console.log("currentWeekIndex change", newVal, oldVal);
        if(newVal<1){
          this.currentWeekIndex = 1
          this.getWeekList()
        }
        else if(newVal>53){
          this.currentWeekIndex = 53
          this.getWeekList()
        }
        else{
          this.getWeekList()
        }
       
      },
    },
  },
}
</script>
<style scoped lang="less">
.calendar {
  text-align: center;
  font-size: 18px;
  //margin: 16px 0;
  color: #363a44;
  //100vh-20px
  min-height: calc(100vh - 90px);
  //min-height: 90vh;
  background-color: #f6f7f9;
  &-year {
    padding: 16px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &-week {
    //border: 1px solid red;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #686b73;
    font-size: 16px;
    margin-bottom: 45px;
    &-item {
      height: 80px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-date {
        width: 46px;
        height: 46px;
        background: #ffffff;
        border-radius: 23px;
        margin: 0 auto;
        line-height: 46px;
        font-weight: 500;
        font-family: PingFangSC, PingFang SC, '微软 PingFangSC-Medium',
          'Helvetica Neue', Helvetica, Arial, sans-serif;
        &.active {
          width: 46px;
          height: 46px;
          background: linear-gradient(180deg, #5edae3 0%, #6f60f4 100%);
          border-radius: 22px;
          color: white;
          position: relative;
          //&::before {
          //  content: '今';
          //  width: 100%;
          //  color: #ffffff;
          //  position: absolute;
          //  top: 0;
          //  left: 0;
          //}
        }
      }
    }
  }
}
.record {
  text-align: left;
  margin-bottom: 16px;
  padding: 0 16px;

  &-item {
    margin-bottom: 10px;
    //border: 1px solid #1492FF;

    &-title {
      font-family: PingFangSC, PingFang SC;
      width: 90%;
      margin: 0 auto;
      font-weight: 500;
      font-size: 16px;
      color: #1492ff;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: -6%;
        top: 5px;
        margin-right: 10px;
        width: 10px;
        height: 10px;
        background: linear-gradient(180deg, #5edae3 0%, #6f60f4 100%);
        border-radius: 50%;
      }
    }
    &-address {
      font-size: 16px;
      color: #686b73;
      text-indent: 1em;
    }
    &-film {
      display: flex;
      align-items: center;
      padding: 0 5%;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: -20px;
        left: 3px;
        display: inline-block;
        width: 2px;
        height: 105%;
        background: #e8e9ec;
      }
      & > div:first-child {
        margin-right: 10px;
      }
    }
  }
}
</style>
